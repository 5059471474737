













import { Component } from "vue-property-decorator";
import { TechAreaModule } from "./store";

import { mixins } from 'vue-class-component';
import SharedMixin from "@/mixins/SharedMixin";

import PgaTechAreasIndex from "./Components/PgaTechAreasIndex.vue";
import { ListTechAreas } from '@/services/iOrderService';


@Component({
	components: {
		PgaTechAreasIndex
	}
})
export default class TechAreasIndex extends mixins(SharedMixin)
{
	private isLoading = true;

	get techAreas(): ListTechAreas
	{
		return TechAreaModule.TechAreasTable;
	}

	async mounted(): Promise<void>
	{
		this.isLoading = true;
		await TechAreaModule.GetAllTechAreas();
		this.isLoading = false;
	}
}
