













import { Component } from "vue-property-decorator";
import { CreateTechAreaModel, TechAreaModule } from "./store";

import { mixins } from 'vue-class-component';
import SharedMixin from "@/mixins/SharedMixin";

import PgaTechAreasCreate from "./Components/PgaTechAreaCreate.vue";
import { CreateTechAreaResponse } from "@/services/iOrderService";

@Component({
	components: {
		PgaTechAreasCreate
	},
})
export default class TechAreasPageCreate extends mixins(SharedMixin) {

	get techAreaCreate(): CreateTechAreaModel
	{
		return TechAreaModule.TechAreaCreate;
	}

	async CreateTechArea(): Promise<void>
	{
		try
		{
			const response: CreateTechAreaResponse = await TechAreaModule.CreateTechArea();
			const id: string = response.Id.toString();
			this.$router.replace({ name: 'techareasdetails', params: { id } });
		}
		// eslint-disable-next-line no-empty
		catch { }
	}

	undo(): void
	{
		this.$router.go(-1);
	}

	async mounted() : Promise<void>
	{
		TechAreaModule.InitTechAreaCreate();
		await TechAreaModule.GetBusinessLinesDropDown();
		await TechAreaModule.GetAreaManagersDropDown();
	}

	beforeDestroy() : void
	{
		TechAreaModule.DropTechAreaCreate();
		TechAreaModule.TechAreaCreate.Errors = new Map<string, Array<string>>();
	}
}
