
















































import { Component, Model } from "vue-property-decorator";
import { TechAreaModel } from "@/services/iOrderService";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

@Component({})
export default class TechAreaDetails extends mixins(SharedMixin)
{
	@Model("", { type: Object }) readonly Model!: TechAreaModel;
}
