





















































import { Component, Model } from "vue-property-decorator";
import { TechAreaModule } from "../store";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { AreaManagerModel, BusinessLineTableView, CreateTechAreaCommand } from '@/services/iOrderService';
import { Guid } from 'guid-typescript';

@Component({})
export default class PgaTechAreasCreate extends mixins(SharedMixin)
{
	@Model("", { type: Object }) readonly Model!: CreateTechAreaCommand;

	get code(): string
	{
		return this.Model?.Code ?? "";
	}
	set code(value: string)
	{
		TechAreaModule.SET_TECHAREA_CODE_CREATE(value);
	}

	get name(): string
	{
		return this.Model?.Name ?? "";
	}
	set name(value: string)
	{
		TechAreaModule.SET_TECHAREA_NAME_CREATE(value);
	}

	get businessLineId(): Guid
	{
		return this.Model?.BusinessLineId ?? Guid.createEmpty();
	}
	set businessLineId(value: Guid)
	{
		TechAreaModule.SET_TECHAREA_BUSINESSLINE_CREATE(value);
	}

	get areaManagerId(): Guid
	{
		return this.Model?.AreaManagerId ?? Guid.createEmpty();
	}
	set areaManagerId(value: Guid)
	{
		TechAreaModule.SET_TECHAREA_AREAMANAGER_CREATE(value);
	}

	get Errors(): Map<string, Array<string>>
	{
		return TechAreaModule.TechAreaCreate.Errors;
	}

	get ListBusinessLines() : Array<BusinessLineTableView>
	{
		return TechAreaModule.BusinessLineDropDown;
	}
	get ListAreaManagers() : Array<AreaManagerModel>
	{
		return TechAreaModule.AreaManagerDropDown;
	}
}
