

















import { Component, Prop } from "vue-property-decorator";
import { TechAreaModel } from "@/services/iOrderService";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

@Component({})
export default class PgaTechAreaIndex extends mixins(SharedMixin)
{
	@Prop({ default: function() { return new Array<TechAreaModel>() } }) readonly TechAreasList!: Array<TechAreaModel>;
    @Prop({ default: true }) readonly isLoading!: boolean;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	headers: Array<any> =
	[
		{
			text: "Code BL/TA",
			align: "start",
			sortable: true,
			value: "Code",
			width: "10%",
			keySearch: "co",
		},
		{
			text: "Business Line",
			allign: "start",
			value: "BusinessLine.Title",
			width: "20%",
			keySearch: "bl",
		},
		{
			text: "Title",
			align: "start",
			sortable: true,
			value: "Name",
			keySearch: "ti",
		},
		{
			text: "Cost Center",
			align: "start",
			sortable: true,
			value: "AreaManager.CostCenter",
			keySearch: "cc",
		},
		{
			text: "Status",
			allign: "start",
			value: "Status",
			width: "10%",
		},
	];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
	rowClicked(item: any): void
	{
		const id = item.Id;
		this.$router.push({ name: "techareasdetails", params: { id } });
	}
}
