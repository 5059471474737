













import { Component } from "vue-property-decorator";
import { EditTechAreaModel, TechAreaModule } from "./store";

import { mixins } from 'vue-class-component';
import SharedMixin from "@/mixins/SharedMixin";

import PgaTechAreasEdit from "./Components/PgaTechAreaEdit.vue";
import { EntityOrderStatus } from "@/services/iOrderService";
import { SharedModule } from "@/feature/Shared/store";

@Component({
	components: {
		PgaTechAreasEdit
	},
})
export default class TechAreasPageEdit extends mixins(SharedMixin) {

	get techAreaEdit(): EditTechAreaModel
	{
		return TechAreaModule.TechAreaEdit;
	}

	async EditTechArea(): Promise<void>
	{
		try
		{
			await TechAreaModule.EditTechArea();
			this.$router.go(-1);
		}
		// eslint-disable-next-line no-empty
		catch { }
	}

	undo(): void
	{
		this.$router.go(-1);
	}

	async mounted() : Promise<void>
	{
		if(TechAreaModule.TechAreaDetails.Status !== EntityOrderStatus.Created)
		{
			SharedModule.SET_ALERT_ERROR_MESSAGE("TechArea is already approved, can't be edit anymore");
			SharedModule.SET_ALERT_IS_VISIBLE(true);
			this.$router.go(-1);
		}

		TechAreaModule.GetTechAreaToEdit();
		await TechAreaModule.GetBusinessLinesDropDown();
		await TechAreaModule.GetAreaManagersDropDown();
	}

	beforeDestroy() : void
	{
		TechAreaModule.DropTechAreaEdit();
		TechAreaModule.TechAreaEdit.Errors = new Map<string, Array<string>>();
	}
}
