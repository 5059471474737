



























































import { Component } from "vue-property-decorator";
import { Guid } from 'guid-typescript';
import { TechAreaModule } from "./store";

import { mixins } from 'vue-class-component';
import SharedMixin from "@/mixins/SharedMixin";
import { SharedModule } from '@/feature/Shared/store';

import PgaTechAreaDetails from "./Components/PgaTechAreaDetails.vue";
import { EntityOrderStatus, TechAreaModel } from "@/services/iOrderService";
import { EmailsStore } from "@/feature/Shared/Email/store";
import { SendEmailToPermissionsCommand } from "@/services/iNotificationService";

@Component( {
	components: {
		PgaTechAreaDetails
	},
} )
export default class TechAreasPageDetails extends mixins( SharedMixin )
{
	private showDialog = false;
	private title = "";
	private message = "";

	get techAreaDetails (): TechAreaModel
	{
		return TechAreaModule.TechAreaDetails;
	}

	get isRequestableDeletion (): boolean
	{
		return this.techAreaDetails.Status !== EntityOrderStatus.DeletionRequest &&
			this.techAreaDetails.Status !== EntityOrderStatus.Created;
	}

	get isApprovable (): boolean 
	{
		return this.techAreaDetails?.Status == EntityOrderStatus.Created;
	}

	get isApprovableDeletion (): boolean 
	{
		return this.techAreaDetails?.Status == EntityOrderStatus.DeletionRequest;
	}

	get isEditable() : boolean
	{
		return this.techAreaDetails?.Status == EntityOrderStatus.Created;
	}

	async approveDeletion (): Promise<void> 
	{
		try
		{
			await TechAreaModule.ApproveDeletionTechArea();
			this.$router.go( -1 );
		}
		catch ( reason )
		{
			SharedModule.SET_ALERT_ERROR_MESSAGE( `error approving deletion business line: ${reason}` );
			SharedModule.SET_ALERT_IS_VISIBLE( true );
		}
	}

	ShowDialogDelete (): void
	{
		this.title = "Deleting TechArea";
		this.message = `Are you sure you want to delete the cost center '${this.techAreaDetails.Name}'?`;
		this.showDialog = true;
	}

	ShowDialogRequestDelete (): void
	{
		this.title = "Request Deletion Business Line";
		this.message = `Are you sure you want to requeste deletion of cost center '${this.techAreaDetails.Name}'?`;
		this.showDialog = true;
	}

	HideDialog (): void
	{
		this.showDialog = false;
		this.title = "";
		this.message = "";
	}

	private Edit() : void
	{
		const id: string = this.techAreaDetails.Id.toString();
		this.$router.push({ name: "techareasedit", params: { id } });
	}

	async deleteTechArea (): Promise<void>
	{
		await TechAreaModule.DeleteTechArea( this.techAreaDetails.Id );

		this.showDialog = false;
		this.$router.go( -1 );
	}

	async approveTechArea (): Promise<void>
	{
		try
		{
			await TechAreaModule.ApproveTechArea();
		}
		catch ( reason )
		{
			SharedModule.SET_ALERT_ERROR_MESSAGE( `error approving TechArea: ${reason}` );
			SharedModule.SET_ALERT_IS_VISIBLE( true );
		}
	}

	private requestChanges()
	{
		const sendEmailCommand : SendEmailToPermissionsCommand = 
		{
			PermissionIds: ["351F5001-3B93-A30D-6D77-1AFF43975C68"],
			Subject: `Request changes for TechArea of ${this.techAreaDetails.Name}`,
			Body: "",
		};
		EmailsStore.SET_SEND_EMAIL_TO_PERMISSIONS_COMMAND(sendEmailCommand);
		EmailsStore.SET_SHOW_FORM_EMAIL_TO_PERMISSIONS(true);
	}

	async mounted (): Promise<void>
	{
		const Id: string = this.$route.params.id;
		TechAreaModule.GetTechAreaDetails( Guid.parse( Id ) );
	}
}
