





















































import { Component, Model } from "vue-property-decorator";
import { Guid } from 'guid-typescript';

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { TechAreaModule } from "../store";
import { AreaManagerModel, BusinessLineTableView, EditTechAreaCommand } from '@/services/iOrderService';

@Component({})
export default class PgaTechAreaEdit extends mixins(SharedMixin)
{
	@Model("", { type: Object }) readonly Model!: EditTechAreaCommand;

	get code(): string
	{
		return this.Model?.Code ?? "";
	}
	set code(value: string)
	{
		TechAreaModule.SET_TECHAREA_EDIT_CODE(value);
	}

	get name(): string
	{
		return this.Model?.Name ?? "";
	}
	set name(value: string)
	{
		TechAreaModule.SET_TECHAREA_EDIT_NAME(value);
	}

	get businessLineId(): Guid
	{
		return this.Model?.BusinessLineId ?? Guid.createEmpty();
	}
	set businessLineId(value: Guid)
	{
		TechAreaModule.SET_TECHAREA_EDIT_BUSINESSLINE_ID(value);
	}

	get areaManagerId(): Guid
	{
		return this.Model?.AreaManagerId ?? Guid.createEmpty();
	}
	set areaManagerId(value: Guid)
	{
		TechAreaModule.SET_TECHAREA_EDIT_AREAMANAGER_ID(value);
	}

	get Errors(): Map<string, Array<string>>
	{
		return TechAreaModule.TechAreaEdit.Errors;
	}

	get ListBusinessLines() : Array<BusinessLineTableView>
	{
		return TechAreaModule.BusinessLineDropDown;
	}
	get ListAreaManagers() : Array<AreaManagerModel>
	{
		return TechAreaModule.AreaManagerDropDown;
	}
}
