import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators'
import store from '@/infrastructure/store';
import { Guid } from 'guid-typescript';

import pgaDiContainer from '@/App.container';
import { SharedModule } from '@/feature/Shared/store';

import IOrderService from '@/services/iOrderService';
import OrderService from '@/services/OrderService';
import { AreaManagerModel, User, BusinessLineStatus, BusinessLineTableView, BusinessLineModel } from '@/services/iOrderService';

import { ListTechAreas, TechAreaModel, EntityOrderStatus,
        ApproveTechAreaCommand, CreateTechAreaCommand, EditTechAreaCommand,
        CreateTechAreaResponse, DeleteTechAreaResponse, EditTechAreaResponse } from '@/services/iOrderService';

export interface TechAreaState
{
    TechAreasTable: ListTechAreas;
    TechAreaDetails: TechAreaModel | null;
    TechAreaCreate: CreateTechAreaModel;
    TechAreaEdit: EditTechAreaModel;
    TechAreaDelete: DeleteTechAreaModel;

    BusinessLineDropDown: Array<BusinessLineTableView>;
    AreaManagerDropDown: Array<AreaManagerModel>;
}

export interface CreateTechAreaModel
{
    Command: CreateTechAreaCommand | null;
    Response: CreateTechAreaResponse | null;
    Errors: Map<string, Array<string>>;
}

export interface EditTechAreaModel
{
    Command: EditTechAreaCommand | null;
    Response: EditTechAreaResponse | null;
    Errors: Map<string, Array<string>>;
}

export interface DeleteTechAreaModel
{
    Id: Guid;
    Response: DeleteTechAreaResponse | null;
}

@Module({ namespaced: true, dynamic: true, store, name: 'TechAreaModule', })
class TechAreasStore extends VuexModule implements TechAreaState
{
    private _orderService: IOrderService = pgaDiContainer.get<IOrderService>(OrderService);

    public TechAreasTable: ListTechAreas =
    {
        TechAreas: new Array<TechAreaModel>(),
    };

    public TechAreaDetails: TechAreaModel =
    {
        Id: Guid.createEmpty(),
        Code: '',
        Name: '',
        Status: EntityOrderStatus.Created,
        BusinessLine:
        {
            Id: Guid.createEmpty(),
            Code: '',
            Title: '',
            ACOM: new Array<User>(),
            Status: BusinessLineStatus.Created,
        },
        AreaManager:
        {
            Id: Guid.createEmpty(),
            CostCenter: '',
            User:
            {
                Id: Guid.createEmpty(),
                FullName: '',
            },
            Status: EntityOrderStatus.Created,
            TenantId: Guid.createEmpty(),
        },
        TenantId: Guid.createEmpty(),
    };

    public TechAreaCreate: CreateTechAreaModel =
    {
        Command: null,
        Response: null,
        Errors: new Map<string, Array<string>>()
    };

    public TechAreaEdit: EditTechAreaModel =
    {
        Command: null,
        Response: null,
        Errors: new Map<string, Array<string>>(),
    }

    public TechAreaDelete: DeleteTechAreaModel =
    {
        Id: Guid.createEmpty(),
        Response: null,
    }


    public BusinessLineDropDown: Array<BusinessLineTableView> = new Array<BusinessLineTableView>();
    public AreaManagerDropDown: Array<AreaManagerModel> = new Array<AreaManagerModel>();


    // DropDown BusinessLine
    @Action({ rawError: true })
    public async GetBusinessLinesDropDown(): Promise<void>
    {
        const bls = await this._orderService.GetBusinessLinesDropdown();
        this.SET_BUSINESSLINES_DROPDOWN(bls);
    }
    @Mutation
    public SET_BUSINESSLINES_DROPDOWN(bls: Array<BusinessLineTableView>)
    {
        this.BusinessLineDropDown = bls;
    }

    // DropDown AreaManager
    @Action({ rawError: true })
    public async GetAreaManagersDropDown(): Promise<void>
    {
        const ams = await this._orderService.GetAllAreaManagersDropdown();
        this.SET_AREAMANAGERS_DROPDOWN(ams.AreaManagers);
    }
    @Mutation
    public SET_AREAMANAGERS_DROPDOWN(ams: Array<AreaManagerModel>)
    {
        this.AreaManagerDropDown = ams;
    }

    // List TechAreas
    @Action({ rawError: true })
    public async GetAllTechAreas()
    {
        const techAreas = await this._orderService.GetAllTechAreas();
        this.SET_TECHAREAS(techAreas);
    }
    @Mutation
    private SET_TECHAREAS(techArea: ListTechAreas): void
    {
        this.TechAreasTable = techArea;
    }


    // Details TechArea
    @Action({ rawError: true })
    public async GetTechAreaDetails(id: Guid)
    {
        SharedModule.SET_ISLOADING(true);
        const techArea: TechAreaModel = await this._orderService.GetTechAreaById(id);
        this.SET_TECHAREA_DETAILS(techArea);
        SharedModule.SET_ISLOADING(false);
    }
    @Action({ rawError: true })
    public DropTechAreaDetails()
    {
        const resetTechAreaDetails: TechAreaModel =
        {
            Id: Guid.createEmpty(),
            Code: '',
            Name: '',
            Status: EntityOrderStatus.Created,
            BusinessLine:
            {
                Id: Guid.createEmpty(),
                Code: '',
                Title: '',
                ACOM: new Array<User>(),
                Status: BusinessLineStatus.Created,
            },
            AreaManager:
            {
                Id: Guid.createEmpty(),
                CostCenter: '',
                User:
                {
                    Id: Guid.createEmpty(),
                    FullName: '',
                },
                Status: EntityOrderStatus.Created,
                TenantId: Guid.createEmpty(),
            },
            TenantId: Guid.createEmpty(),
        };
        this.SET_TECHAREA_DETAILS(resetTechAreaDetails);
    }
    @Mutation
    public SET_TECHAREA_DETAILS(techArea: TechAreaModel)
    {
        this.TechAreaDetails = techArea;
    }
    @Mutation
    public SET_TECHAREA_ID(Id: Guid) {
        this.TechAreaDetails.Id = Id;
    }
    @Mutation
    public SET_TECHAREA_CODE(code: string) {
        this.TechAreaDetails.Code = code;
    }
    @Mutation
    public SET_TECHAREA_NAME(Name: string) {
        this.TechAreaDetails.Name = Name;
    }
    @Mutation
    public SET_TECHAREA_TENANT(TenantId: Guid) {
        this.TechAreaDetails.TenantId = TenantId;
    }
    @Mutation
    public SET_TECHAREA_STATUS(Status: EntityOrderStatus) {
        this.TechAreaDetails.Status = Status;
    }
    @Mutation
    public SET_TECHAREA_BUSINESSINE(businessLine: BusinessLineModel) {
        this.TechAreaDetails.BusinessLine = businessLine   
    }
    @Mutation
    public SET_TECHAREA_AREAMANAGER(areaManager: AreaManagerModel) {
        this.TechAreaDetails.AreaManager = areaManager   
    }

    // Create TechArea
    @Action({ rawError: true })
    public async CreateTechArea(): Promise<CreateTechAreaResponse>
    {
        try {
            let response: CreateTechAreaResponse = { Id: Guid.createEmpty() };
            SharedModule.SET_ISLOADING(true);
            
            if (this.TechAreaCreate.Command)
                response = await this._orderService.CreateTechArea(this.TechAreaCreate.Command);

            SharedModule.SET_ISLOADING(false);
            return response;
        }
        catch (error) {
            SharedModule.SET_ISLOADING(false);
            const errs = parseErrors(error);
            this.SET_TECHAREA_CREATE_ERRORS(errs);

            throw error;
        }
    }
    @Action({ rawError: true })
    public InitTechAreaCreate()
    {
        const command: CreateTechAreaCommand =
        {
            Code: '',
            Name: '',
            BusinessLineId: null,            
            AreaManagerId: null,
        }

        this.SET_TECHAREA_CREATE(command);
    }
    @Action({ rawError: true })
    public async DropTechAreaCreate()
    {
        this.SET_TECHAREA_CREATE(null);
    }
    @Mutation
    public SET_TECHAREA_CREATE(command: CreateTechAreaCommand | null)
    {
        this.TechAreaCreate.Command = command;
    }
    @Mutation
    public SET_TECHAREA_CODE_CREATE(code: string)
    {
        if(this.TechAreaCreate.Command)
            this.TechAreaCreate.Command.Code = code;
    }
    @Mutation
    public SET_TECHAREA_NAME_CREATE(Name: string)
    {
        if(this.TechAreaCreate.Command)
            this.TechAreaCreate.Command.Name = Name;
    }
    @Mutation
    public SET_TECHAREA_BUSINESSLINE_CREATE(businessLineId: Guid)
    {
        if(this.TechAreaCreate.Command)
            this.TechAreaCreate.Command.BusinessLineId = businessLineId;
    }
    @Mutation
    public SET_TECHAREA_AREAMANAGER_CREATE(areaManagerId: Guid)
    {
        if(this.TechAreaCreate.Command)
            this.TechAreaCreate.Command.AreaManagerId = areaManagerId;
    }
    @Mutation
    public SET_TECHAREA_CREATE_ERRORS(errors: Map<string, Array<string>>)
    {
        this.TechAreaCreate.Errors = errors;
    }

    // EDIT TECHAREA
    @Action({ rawError: true })
    public async EditTechArea() : Promise<EditTechAreaResponse>
    {
        try {
            let response = { Id: Guid.createEmpty() };
            SharedModule.SET_ISLOADING(true);
            if (this.TechAreaEdit.Command)
                response = await this._orderService.EditTechArea(this.TechAreaEdit.Command.Id, this.TechAreaEdit.Command);
            SharedModule.SET_ISLOADING(false);
            return response;
        }
        catch (error) {
            SharedModule.SET_ISLOADING(false);
            const errs = parseErrors(error);
            this.SET_TECHAREA_EDIT_ERRORS(errs);
            throw errs;
        }
    }
    @Action({ rawError: true })
    public async GetTechAreaToEdit() {
        SharedModule.SET_ISLOADING(true);
        const ta: TechAreaModel = await this._orderService.GetTechAreaById(this.TechAreaDetails.Id);

        const editTechAreaCommand: EditTechAreaCommand = 
        {
            Id: ta.Id,
            Code: ta.Code,
            Name: ta.Name,
            BusinessLineId: ta.BusinessLine.Id,
            AreaManagerId: ta.AreaManager.Id,
        }

        this.SET_TECHAREA_EDIT(editTechAreaCommand);
        SharedModule.SET_ISLOADING(false);
    }
    @Action({ rawError: true })
    public DropTechAreaEdit()
    {
        this.SET_TECHAREA_EDIT(null);
        this.SET_TECHAREA_EDIT_ERRORS(new Map<string, Array<string>>());
    }
    @Mutation
    public SET_TECHAREA_EDIT(economicLimit: EditTechAreaCommand | null)
    {
        this.TechAreaEdit.Command = economicLimit;
    }
    @Mutation
    public SET_TECHAREA_EDIT_ID(id: Guid)
    {
        if (this.TechAreaEdit.Command)
            this.TechAreaEdit.Command.Id = id;
    }
    @Mutation
    public SET_TECHAREA_EDIT_CODE(code: string) 
    {
        if (this.TechAreaEdit.Command)
            this.TechAreaEdit.Command.Code = code;
    }
    @Mutation
    public SET_TECHAREA_EDIT_NAME(name: string)
    {
        if (this.TechAreaEdit.Command)
            this.TechAreaEdit.Command.Name = name;
    }
    @Mutation
    public SET_TECHAREA_EDIT_BUSINESSLINE_ID(businessLineId: Guid)
    {
        if (this.TechAreaEdit.Command)
            this.TechAreaEdit.Command.BusinessLineId = businessLineId;
    }
    @Mutation
    public SET_TECHAREA_EDIT_AREAMANAGER_ID(areaManagerId: Guid)
    {
        if (this.TechAreaEdit.Command)
            this.TechAreaEdit.Command.AreaManagerId = areaManagerId;
    }
    @Mutation
    public SET_TECHAREA_EDIT_ERRORS(errors: Map<string, Array<string>>) {
        this.TechAreaEdit.Errors = errors;
    }


    // Delete TechArea
    @Action({ rawError: true })
    public async DeleteTechArea(id: Guid): Promise<DeleteTechAreaResponse>
    {
        try {
            let response: DeleteTechAreaResponse = { Id: Guid.createEmpty() };
            SharedModule.SET_ISLOADING(true);
            this.SET_TECHAREA_DELETE(id);

            if (this.TechAreaDelete)
                response = await this._orderService.DeleteTechArea(this.TechAreaDelete.Id);
            SharedModule.SET_ISLOADING(false);
            return response
        }
        catch(error) {
            SharedModule.SET_ISLOADING(false);

            throw error;
        }
    }
    @Mutation
    public SET_TECHAREA_DELETE(id: Guid)
    {
        this.TechAreaDelete.Id = id;
    }

    // APPROVE TECH AREA
    @Action({ rawError: true })
    public async ApproveTechArea(): Promise<void> {
        SharedModule.SET_ISLOADING(true);
        try {
            if (this.TechAreaDetails == null)
                return Promise.reject("Details Supply Tipology not initialized, try reload the application");

            const ta = this.TechAreaDetails;
            const cmd: ApproveTechAreaCommand = { Id: ta.Id };
            await this._orderService.ApproveTechArea(cmd);

            this.SET_TECHAREA_STATUS(EntityOrderStatus.Approved);
        } finally {
            SharedModule.SET_ISLOADING(false);
        }
    }

    // APPROVE DELETION
    @Action({ rawError: true })
    public async ApproveDeletionTechArea(): Promise<void> {
        SharedModule.SET_ISLOADING(true);
        try {
            if (this.TechAreaDetails == null)
                return Promise.reject("Details TechArea not initialized, try reload the application");

            const ta = this.TechAreaDetails;
            const cmd = { Id: ta.Id };
            await this._orderService.ApproveDeletionTechArea(cmd);

            this.SET_TECHAREA_STATUS(EntityOrderStatus.Deleted);
        } finally {
            SharedModule.SET_ISLOADING(false);
        }
    }
}

function parseErrors(error: any): Map<string, Array<string>> {
    const errs = new Map<string, Array<string>>();

    for (const [key, value] of Object.entries(error.response.data)) {
        const messages = value as Array<string>;
        errs.set(key, messages);
    }

    return errs;
}

export const TechAreaModule = getModule(TechAreasStore);